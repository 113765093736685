import React, { useState } from 'react';
import './personcard.scss';
import { Button, Card, Col, Image, message, Row, Tooltip, Typography } from 'antd';
import { FiLink2 } from '@react-icons/all-files/fi/FiLink2';
import { Link } from 'react-router-dom';
import { ProfileProps } from '../../body/Home/PeopleTab/PeopleTabSlice';
import { TagType } from '../TagType/TagType';
import defaultProfilePic from '../../../../src/assets/images/default_user_image.svg';
import defaultCoverPic from '../../../../src/assets/images/cover-photo-small.jpeg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsChatLeftTextFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks';
import { getUniversityResourceSelector } from '../../../MainScreen/MainScreenSlice';
import topRatedBadgePic from '../../../assets//images/badges/top-rated.svg';
import { getReplyTime } from '../utils';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import { VideoPreviewModal } from '../VideoPreviewModal/VideoPreviewModal';
import { PersonalDetailViewModal } from './PersonalDetailViewModal';
import { isBrowser, isMobile } from 'react-device-detect';

const { Paragraph } = Typography;

type PersonCardType = {
  setVisible: any;
  createdDate?: [];
  domain?: string;
  id: number;
  posts?: [];
  profile: ProfileProps;
  profileLink: string;
  ratings?: [];
  replyTime: {
    unit: string;
    count: number;
  };
  setProfileId: any;
  enableWordPress: boolean;
};

function PersonCard({
  profile,
  id,
  replyTime,
  profileLink,
  setVisible,
  setProfileId,
  enableWordPress,
}: PersonCardType) {
  const [messageApi, contextHolder] = message.useMessage();
  const [visibleVideModal, setVisibleVideoModal] = useState<boolean>(false);
  const [visibleDetailModal, setVisibleDetailModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState(null);

  const showModal = (id: number) => {
    setVisible(true);
    setProfileId(id);
  };
  const { t } = useTranslation();
  const { payload } = useAppSelector(getUniversityResourceSelector);

  const handleVideoModal = () => {
    if (profile?.introVideoUrl) {
      setVisibleVideoModal(true);
    }
  };

  const handleVideoModalCancel = () => {
    setVisibleVideoModal(false);
  };

  const visibleMessagePopup = () => {
    showModal(id);
    setVisibleVideoModal(false);
  };

  const handleViewProfile = (id: any) => {
    setSelectedId(id);
    setVisibleDetailModal(true);
  };

  const handlePersonalViewModal = () => {
    setVisibleDetailModal(false);
  };

  const colSpan = enableWordPress ? 6 : 8;
  const mobileColSpan = enableWordPress ? 8 : 12;
  const cardClassNames = `inPersonCard inPersoncardMobile ${enableWordPress ? 'enableWordpressCard' : ''}`;

  return (
    <Col xxl={6} lg={colSpan} md={mobileColSpan} xs={12} sm={12}>
      <div className="personCard personCardMobile ">
        <PersonalDetailViewModal
          selectedId={selectedId}
          visibleDetailModal={visibleDetailModal}
          handlePersonalViewModal={handlePersonalViewModal}
          enableWordPress={enableWordPress}
        />
        <VideoPreviewModal
          visibleVideModal={visibleVideModal}
          handleVideoModalCancel={handleVideoModalCancel}
          firstName={profile?.firstName}
          programme={profile?.programme}
          smallProfileUrl={profile?.smallProfileUrl}
          introVideoUrl={profile?.introVideoUrl}
          themeColor={payload?.theme?.themeColor}
          visibleMessagePopup={visibleMessagePopup}
        />
        <Card
          className={cardClassNames}
          style={{ margin: '0 auto', height: '510px' }}
          cover={
            <div className="cover">
              <div className="coverImageBackroundLayout" />
              <img
                className="coverImageBackround coverImageBackroundMobile"
                src={profile?.banner?.imageUrl || defaultCoverPic}
                alt={t('empty') || ''}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = defaultCoverPic;
                }}
              />

              <div className="topPanel">
                <TagType type={profile.type} />

                <div className="copyLinkButton copyLinkButtonMobile">
                  {contextHolder}
                  <CopyToClipboard text={profileLink}>
                    <Tooltip title="Copy">
                      <Button
                        shape="circle"
                        size="small"
                        className="incopyLinkButton"
                        icon={<FiLink2 />}
                        ghost
                        onClick={(event) => {
                          messageApi.open({
                            type: 'success',
                            content: 'Copied',
                          });
                        }}
                      />
                    </Tooltip>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="coverImageForeground">
                <img
                  src={profile?.smallProfileUrl || defaultProfilePic}
                  alt={profile && profile.firstName}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = defaultProfilePic;
                  }}
                  // className="img imgMobile coverImageForegroundUserImage"
                  className={`img imgMobile coverImageForegroundUserImage ${
                    profile?.introVideoUrl ? 'imgOutline' : ''
                  }`}
                  onClick={handleVideoModal}
                  // style={profile?.introVideoUrl ? { outline: `3px solid ${payload?.theme?.themeColor}` } : {}}
                />

                <div className="userBadge d-none">
                  <img src={topRatedBadgePic} alt="Top Badge Pic" />
                </div>
              </div>
            </div>
          }
        >
          <div
            className="personCardIn personCardInMobile"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '250px',
            }}
          >
            <div className="title">
              <Tooltip
                title={isBrowser && profile && (profile?.displayName ?? profile?.firstName)}
                placement="top"
                color="#36454F"
                align={{ offset: [0, 10] }}
              >
                <Paragraph style={{ padding: 0, margin: '0' }} ellipsis={{ rows: 1, expandable: false }}>
                  {profile && (profile?.displayName ?? profile?.firstName)}
                </Paragraph>
              </Tooltip>

              {isMobile && profile?.country?.code && (
                <span className="titleCountryFlag">
                  <span className={`fi fi-${profile?.country?.code.toLowerCase()}`}></span>
                </span>
              )}
            </div>
            <div className="studiesTitle">
              <Tooltip
                title={isBrowser && profile?.courseInfo?.displayName}
                placement="top"
                color="#36454F"
                align={{ offset: [0, 10] }}
              >
                <Paragraph ellipsis={{ rows: 2, expandable: false }}>{profile?.courseInfo?.displayName}</Paragraph>
              </Tooltip>
              <Tooltip
                title={isBrowser && profile?.programme}
                placement="top"
                color="#36454F"
                align={{ offset: [0, 10] }}
              >
                <Paragraph className="courseLevelTitle" ellipsis={{ rows: 1, expandable: false }}>
                  {profile?.programme}
                </Paragraph>
              </Tooltip>
            </div>
            {(profile?.type === 'Staff' || profile?.type === 'Parent') && (
              <div className="roleTitle">
                {profile?.role}
                <h3 className="workTitle">{profile?.department || profile?.companyName}</h3>
              </div>
            )}

            {/*<div className="studiesCompletedTitle">*/}
            {/*  <Button type="link" size="small">*/}
            {/*    {profile?.profession}*/}
            {/*  </Button>*/}
            {/*</div>*/}
            <div className="languages languagesMobile">
              <p className="languagesTitle">{t('dashboard.personCard.countriesName')} </p>
              <div className="countryContainer">
                {profile?.country?.code && (
                  <span className="userCoutryFlag">
                    <span className={`fi fi-${profile?.country?.code.toLowerCase()}`}></span>
                  </span>
                )}
                <span className="countryName">
                  {profile?.state && `${profile?.state} | `}
                  {profile?.country?.name}
                </span>
              </div>
            </div>
            <div className="languages languagesMobile">
              <p className="languagesTitle">{t('dashboard.personCard.languagesTitle')} </p>
              {/* <div className="languagesIn">
                {profile &&
                  profile?.languages &&
                  profile?.languages?.length > 0 &&
                  profile?.languages.map((lang: { code: string; name: string; url: string }) => {
                    return <span>{lang.name} </span>;
                  })}
              </div> */}
              {profile && profile?.languages && (
                <div className="languagesIn">
                  {profile?.languages?.length > 0 &&
                    profile?.languages.slice(0, 2).map((lang: { code: string; name: string; url: string }) => {
                      return <span style={{ color: '#808080' }}>{lang.name} </span>;
                    })}
                  {profile?.languages.length > 2 && (
                    <Tooltip
                      placement="top"
                      title={profile?.languages
                        .slice(2)
                        .map((lang) => lang.name)
                        .join(', ')}
                    >
                      <div>+{profile?.languages.length - 2}</div>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>

            <div className="hobby hobbyMobile">
              <p className="hobbyTitle"> {t('dashboard.personCard.aboutMe')}</p>
              <div className="hobbyIconArea">
                {/* {profile &&
                  profile.interests &&
                  profile.interests.slice(0, 3).map((intereset) => {
                    return (
                      <div className="hobbyIconLayout">
                        {intereset.url ? (
                          <Tooltip placement="bottom" title={intereset.name}>
                            <Image
                              className="hobbyIcon"
                              src={intereset.url}
                              // src="https://univiserstg.blob.core.windows.net/public/hiking_black.svg"
                              preview={false}
                            />
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                {profile && profile.interests && profile.interests.length > 3 && (
                  <Tooltip
                    placement="top"
                    align={{ offset: [5, 10] }}
                    title={profile.interests
                      .slice(3)
                      .map((i) => i.name)
                      .join(', ')}
                  >
                    <div className="hobbyIconLayout">+{profile.interests.length - 3}</div>
                  </Tooltip>
                )} */}
                {profile && (
                  <Paragraph
                    ellipsis={{ rows: 2, expandable: false }}
                    style={{ padding: '0px 6px', fontWeight: '200', color: '#808080' }}
                  >
                    {profile.description}
                  </Paragraph>
                )}
              </div>
            </div>
            {/* <div className="buddyAvgResponseTime buddyAvgResponseTimeMobile">
             {t("dashboard.personCard.buddyAvgResponseTime")}{" "}
               {getReplyTime(replyTime.count)}
             </div> */}
          </div>
          <div className="userChatButton userChatButtonMobile">
            {/*<SayHelloPopup profile={profile} replyTime={replyTime} profileLink={profileLink} id={id}/>*/}

            <Button
              onClick={() => showModal(id)}
              className="inUserChatButton inUserChatButtonMobile"
              type="primary"
              style={{
                backgroundColor: `${payload?.theme?.themeColor}`,
                borderColor: `${payload?.theme?.themeColor}`,
              }}
              icon={<BsChatLeftTextFill />}
            >
              {t('dashboard.personCard.userChatButton')}
            </Button>
            {enableWordPress ? (
              <Button
                className="viewProfileButton"
                style={{
                  color: `${payload?.theme?.themeColor}`,
                  border: 'unset',
                }}
                onClick={() => handleViewProfile(id)}
              >
                {t('dashboard.personCard.viewProfileButtonIn')}
              </Button>
            ) : (
              <Link
                className="viewProfileButton"
                style={{ color: `${payload?.theme?.themeColor}` }}
                to={{
                  pathname: `/buddy/${id}`,
                }}
              >
                {t('dashboard.personCard.viewProfileButtonIn')}
              </Link>
            )}
          </div>
        </Card>
      </div>
    </Col>
  );
}

export default PersonCard;
