import Ribbon from "antd/lib/badge/Ribbon";
import React from "react";
import "./TagType.scss";

export type TagProp = {
  type: "Student" | "Staff" | "Alumni" | "Parent";
};
export const TagType = ({ type }: TagProp) => {
  if (type === "Student") {
    return (
      <>
        <Ribbon className="tagType tagTypeMobile" text={"Student"} color={"#0DA060"} />
      </>
    );
  } else if (type === "Alumni") {
    return (
      <>
        <Ribbon className="tagType userRoleBadgeMobile" text={type} color={"blue"} />
      </>
    );
  } else if (type === "Staff") {
    return <Ribbon className="tagType userRoleBadgeMobile" text={type} color={"purple"} />;
  } else {
    return <Ribbon className="tagType userRoleBadgeMobile" text={type} color={"orange"} />;
  }
};
