export function PathParamGenerator(params :any) {
let keys = Object.keys(params)
    let paramList =[]

    for (let i = 0; i < keys.length ; i++) {
        if(params[keys[i]] !== undefined && params[keys[i]]!==''){
            paramList.push(`${keys[i]}=${params[keys[i]]}`)
        }
    }
    return `?${paramList.join("&")}`
}