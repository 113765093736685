export function getReplyTime(replyTime: number): string {
  // Assumes that reply time is always returned in minutes from backend
  if (replyTime <= 60) {
    return replyTime + ' ' + 'min';
  } else if (replyTime > 60 && replyTime <= 1440) {
    return (replyTime / 60).toFixed(2) + ' ' + 'hours';
  } else {
    return (replyTime / 1440).toFixed(2) + ' ' + 'days';
  }
}

// Domain validation for hide features (need to remove this in the future)

const compareDomain = (domain: any) => {
  const restrictedDomains = [
    'shoolini.univiser.io',
    'amraksl.univiser.io',
    'ecusl.univiser.io',
    'uclsl.univiser.io',
    'ricsl.univiser.io',
    'mitwpu.univiser.io',
    'drmgrv2.univiser.io',
    'drmgronline.univiser.io',
    // 'demo.univiser.io',
  ];
  return restrictedDomains.includes(domain);
};

export const domainValidation = (universityResourceState: any) => !compareDomain(universityResourceState?.domain);
