export const getHost = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_BASE_ID;
  } else {
    if (window?.location?.host) {
      return window?.location?.host;
    }
    return '';
  }
};
