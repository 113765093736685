import { Button, Image, List, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import "./starMsg.scss";
import { useAppDispatch } from "../../../../hooks";
import { useSelector } from "react-redux";
import { ChatBuddyProps } from "../../../Models/ChatBuddyProps";
import { getChatArea } from "../ChatArea/ChatAreaSlice";
import { setBuddyID } from "../SetSelectBuddySlice";
import {
  clearReadChatMessage,
  readChatMessage,
  readChatMessageSelector,
} from "../ChatArea/ReadMessage/ReadMessageSlice";
import { useTranslation } from "react-i18next";
import { getAllFavMessagesSelector } from "./FavMessageSwitchSlice";
import { setFavMessage } from "../FavMessageSwitch/FavMessageSwitchSlice";
import { MdClose } from "react-icons/md";
import { useParams } from "react-router-dom";

function FavMessageList({ setSwitchFavMessages }: any) {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [selectedId, setSelectedId] = useState<ChatBuddyProps>();
  const { t, i18n } = useTranslation();
  const {} = i18n;
  let { isFetching: favListIsFetching, favMessageListResp } = useSelector(
    getAllFavMessagesSelector
  );
  let { status: readMessageStatus } = useSelector(readChatMessageSelector);
  let sessionDetails = JSON.parse(
    localStorage.getItem("sessionDetails") as string
  );

  useEffect(() => {
    if (selectedId?.publicId) {
      dispatch(getChatArea({ id: parseInt(selectedId.publicId), pubId: id }));
      dispatch(setBuddyID(selectedId));
      dispatch(
        readChatMessage({
          buddyID: parseInt(selectedId.publicId),
          pubId: id,
        })
      );
    }
  }, [selectedId]);

  useEffect(() => {
    if (readMessageStatus === "SUCCESS") {
      dispatch(clearReadChatMessage());
    }
  }, [readMessageStatus]);

  function onBuddyClick(item: ChatBuddyProps) {
    setSelectedId(item);
  }

  function removeFavMessage(item: any) {
    dispatch(
      setFavMessage({
        messageId: parseInt(item.id),
        prospectId: sessionDetails.prospectId,
        status: false,
      })
    );
  }

  return (
    <>
      {/* <div>
        <MainScreen/>
      </div> */}

      {/* width_0px */}
      {/* className={'wrapper searchDiv ' + this.state.something} */}
      {/* className={`App ${border}`} */}
      <List className={`starMsgLayout`}>
        <PageHeader
          className="site-page-header"
          onBack={() => setSwitchFavMessages(false)}
          title={t("Chat.BuddyList.starredDropDownBtn")}
        />
        <div className="myChatBuddiesView">
          {favMessageListResp && favMessageListResp.length > 0 && (
            <List
              className="buddyListBody staredMsgBody"
              dataSource={favMessageListResp}
              loading={favListIsFetching}
              renderItem={(item: any, index) => (
                <List.Item
                  className={`listLayout ${
                    item?.publicId === (selectedId?.publicId || 0)
                      ? "active"
                      : ""
                  }`}
                  // className="listLayout active"
                  // style={item?.publicId === (selectedId?.publicId || 0) ? {
                  //     backgroundColor: '#09577c',
                  //     color: '#fafafa'
                  // } : {}}
                  key={index}
                >
                  <List.Item.Meta
                    className="senderInfoArea"
                    title={
                      <a className="senderInfo">
                        {item.from} to {item.to}
                      </a>
                    }
                    description={
                      item.mediaType === "MESSAGE" ? (
                        <div
                          className={`chatBubble ${
                            item.type === "B_TO_P" ? "reply" : ""
                          }`}
                        >
                          {item.body}
                        </div>
                      ) : (
                        <div
                          className={`chatBubble ${
                            item.type === "B_TO_P" ? "reply" : ""
                          }`}
                        >
                          <Image src={item.body} />
                        </div>
                      )
                    }
                  />
                  <div className="timeTagLayout">
                    <p className="lastChatDate">{item.dateCreated}</p>
                  </div>
                  <Button
                    className="iconRemove"
                    onClick={() => removeFavMessage(item)}
                  >
                    <MdClose />
                  </Button>
                </List.Item>
              )}
            />
          )}
        </div>
      </List>
    </>
  );
}

export default FavMessageList;
