import { AddLikeToBuddyFeedProps, GetBuddyFeedProps, GetStudentFeedsProps } from './studentLifeInterfaces';

export const getAllStudentFeedsAPIRequest = async (data: GetStudentFeedsProps) => {
  // console.log("=== data ===", data);

  return await fetch(`${process.env.REACT_APP_API_URL}/post/university/${data?.uniId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      'X-Forwarded-For': '',
    },
  }).then((res) => res.json());
};

export const getBuddyFeedsAPIRequest = async (data: GetBuddyFeedProps) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/post/buddy/${data?.buddyId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      'X-Forwarded-For': '',
    },
  }).then((res) => res.json());
};

// export const addLikeToBuddyFeedAPIRequest = async (data: AddLikeToBuddyFeedProps) => {
//   return await fetch(`${process.env.REACT_APP_API_URL}/post/${data?.postId}/like`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       // uniId: "ric.univiser.io" as string,
//     },
//     body: JSON.stringify({ ip: data.ip }),
//   }).then((res) => res.json());
// };

export const addLikeToBuddyFeedAPIRequest = async (data: AddLikeToBuddyFeedProps) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/post/${data?.postId}/like`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // uniId: "ric.univiser.io" as string,
      },
      body: JSON.stringify({ ip: data.ip }),
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    throw error;
  }
};
