import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Define a function to initialize i18n with a dynamic loadPath
const initializeI18n = (apiDomain: any) => {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng: 'en',
      fallbackLng: 'en',
      defaultNS: 'translation',
      ns: ['translation'],
      initImmediate: true,
      returnNull: false,
      supportedLngs: ['en', 'tamil', 'spanish', 'fr'],
      debug: false,
      keySeparator: '.',
      react: {
        useSuspense: true,
      },
      backend: {
        // Use the apiDomain as the loadPath
        loadPath: `${apiDomain}/locales/{{lng}}/{{ns}}.json`,
      },
      interpolation: {
        escapeValue: false,
      },
    });

  return i18n;
};

export default initializeI18n;
