import { AddBuddyRatingProps, GetBudyDetailsProps, GetUniversityDetailsProps } from './AddBuddyRatingSlice';

export const addBuddyRatingAPIRequest = async (rating: AddBuddyRatingProps) => {
  return await fetch(
    `${process.env.REACT_APP_API_URL}/support/prospect/${rating?.prospectPublicId}/buddy/${rating?.buddyPublicId}/rating`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uniId: 'ric.univiser.io' as string,
      },
      body: JSON.stringify({ comment: rating.comment, ratings: rating.ratings }),
    }
  ).then((res) => res.json());
};

export const fetchUniversityDetailsAPIRequest = async (uniId: GetUniversityDetailsProps) => {
  return await fetch(`${process.env.REACT_APP_API_URL}/university/${uniId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      'X-Forwarded-For': '',
    },
  }).then((res) => res.json());
};

export const fetchBuddyDetailsAPIRequest = async (publicIds: GetBudyDetailsProps) => {
  return await fetch(
    `${process.env.REACT_APP_API_URL}/university/${publicIds.prospectPublicId}/buddy/public/${publicIds.buddyPublicId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        'X-Forwarded-For': '',
      },
    }
  ).then((res) => res.json());
};
