import { Card } from 'antd';
import SampleWallPaper from '../../../../assets/images/sample-wall-paper.png';
import { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import './CustomCarousel.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const Gallery = ({ media, handlePreviewMedia }: any) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const sliderRef = useRef<Slider | null>(null);

  const sliderSetting = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '10px',
    slidesToShow: media?.length >= 3 ? 3 : media?.length,
    speed: 700,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: media?.length >= 3 ? 2 : media?.length,
          centerPadding: '10px',
        },
      },
    ],
    afterChange: (currentSlide: number) => {
      const currentItem = media[currentSlide];
      handlePreviewMedia(currentItem);
    },
  };

  const handleImageClick = (item: any, index: number) => {
    setActiveSlideIndex(index);
    handlePreviewMedia(item);
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const getSliderClassNames = () => {
    if (media?.length === 1 && isMobile) {
      return 'single-item-mobile';
    }
    if (media?.length <= 3 && !isMobile) {
      return 'min-items';
    }
    return '';
  };

  return (
    <>
      <Slider ref={sliderRef} {...sliderSetting} className={getSliderClassNames()}>
        {' '}
        {media?.map((item: any, index: number) => (
          <div key={index} onClick={() => handleImageClick(item, index)}>
            <img
              alt="Slider pic"
              src={item?.url}
              role="presentation"
              className={`slPostDetailGalleryImage ${activeSlideIndex === index ? 'slick-current' : ''}`}
            />
          </div>
        ))}
      </Slider>
    </>
  );
};
