import { getHost } from '../../../../../utils/hostDataFunction';

export type MessageProps = {
  buddyID: number;
};

export const sendAttachmentMessageRequest = async (message: MessageProps) => {
  let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string);
  let uniId = getHost();
  return await fetch(`${process.env.REACT_APP_API_URL}/chat/buddy/${message.buddyID}/history/media`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      uniId: uniId ? uniId : '',
      session: sessionDetails?.sessionId,
    },
  }).then((res) => res.json());
};
