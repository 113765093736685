import moment from 'moment';

export const handleBuddyDetails = (id: any, payload: any) => {
  const buddyDetails =
    payload?.items &&
    payload?.items?.filter((item: any) => {
      return id === item?.id;
    });
  return buddyDetails && buddyDetails[0];
};

export const handleBuddyName = (id: any, payload: any) => {
  const buddy = handleBuddyDetails(id, payload);
  return buddy && buddy?.profile?.firstName;
};

export const handleBuddyAvatar = (id: any, payload: any) => {
  const buddy = handleBuddyDetails(id, payload);
  return buddy && buddy?.profile?.smallProfileUrl;
};

export const handleMainImageVideo = (payload: any) => {
  const leastOrderObject =
    payload?.length > 0 &&
    payload?.reduce((minObj: any, currentObj: any) => {
      if (currentObj.order < minObj.order) {
        return currentObj;
      }
      return minObj;
    });
  return leastOrderObject;
};

export const checkMediaTypeByUrl = (url: any) => {
  if (/\.(jpe?g|png|gif|bmp|jfif)$/i.test(url)) {
    return 'image';
  } else if (/\.(mp4|avi|mkv|mov|webm)$/i.test(url)) {
    return 'video';
  } else {
    return 'unknown';
  }
};

export const calculateTimeAgo = (createdData: any) => {
  const createdDate = moment.utc(createdData);
  const currentDate = moment();
  const localCreatedDate = moment(createdDate).local();
  const timeDifference = currentDate.diff(localCreatedDate, 'seconds');

  if (timeDifference < 60) {
    // Less than 60 seconds, display seconds ago
    return `${Math.floor(timeDifference)} seconds ago`;
  } else if (timeDifference < 3600) {
    // Less than 1 hour, display minutes ago
    return `${Math.floor(timeDifference / 60)} minutes ago`;
  } else if (timeDifference < 86400) {
    // Less than 1 day, display hours ago
    return `${Math.floor(timeDifference / 3600)} hours ago`;
  } else if (timeDifference < 604800) {
    // Less than 1 week, display days ago
    return `${Math.floor(timeDifference / 86400)} days ago`;
  } else if (timeDifference < 2419200) {
    // Less than 4 weeks (approximately 1 month), display weeks ago
    return `${Math.floor(timeDifference / 604800)} weeks ago`;
  } else {
    // More than 1 month, display months ago
    const monthsAgo = Math.floor(timeDifference / 2419200);
    return `${monthsAgo} ${monthsAgo === 1 ? 'month' : 'months'} ago`;
  }
};
