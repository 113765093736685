export type BuddyInfoReqProps = {
    id: string
    universityId: string
}


export const getBuddyDetailstRequest = async (buddyInfoReqProps: BuddyInfoReqProps) => {
    // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
    // console.log("user",user)
    return await fetch(
        `${process.env.REACT_APP_API_URL}/university/${buddyInfoReqProps.universityId}/buddy/${buddyInfoReqProps.id}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*'
            },
            // body: JSON.stringify({
            //     auth_token:user.auth_token
            // }),
        }
    )
        .then((res) => res.json())
};
