import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AddLikeToBuddyFeedProps, GetBuddyFeedProps, GetStudentFeedsProps } from './studentLifeInterfaces';
import { addLikeToBuddyFeedAPIRequest, getAllStudentFeedsAPIRequest, getBuddyFeedsAPIRequest } from './StudentLifeAPI';
import { RootState } from '../../../store';

interface StudentLifeFeedProps {
  getAllFeedsStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  isFetchingAllFeeds: boolean;
  getAllFeedsResponse: any;

  getBuddyFeedStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  isFetchingBuddyFeed: boolean;
  getBuddyFeedResponse: {};

  addFeedLikeStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  isFetchingAddLike: boolean;
  addBuddyFeedResponse: {};
  likedPostId: any;
  error: any;
}

const initialState: StudentLifeFeedProps = {
  getAllFeedsStatus: 'IDLE',
  isFetchingAllFeeds: false,
  getAllFeedsResponse: {},

  getBuddyFeedStatus: 'IDLE',
  isFetchingBuddyFeed: false,
  getBuddyFeedResponse: {},

  addFeedLikeStatus: 'IDLE',
  isFetchingAddLike: false,
  addBuddyFeedResponse: {},
  likedPostId: null,
  error: {},
};

export const getAllStudentFeedsRequest = createAsyncThunk('get/posts', async (uniId: GetStudentFeedsProps) => {
  return await getAllStudentFeedsAPIRequest(uniId);
});

export const getBuddyFeedsRequest = createAsyncThunk('get/posts/id', async (buddyId: GetBuddyFeedProps) => {
  return await getBuddyFeedsAPIRequest(buddyId);
});

export const addLikeToBuddyFeedRequest = createAsyncThunk('post/posts/id', async (data: AddLikeToBuddyFeedProps) => {
  return await addLikeToBuddyFeedAPIRequest(data);
});

const StudenLifeSlice = createSlice({
  name: 'studentLife',
  initialState: initialState,
  reducers: {
    clearAllFeeds(state) {
      state.getAllFeedsStatus = 'IDLE';
      state.isFetchingAllFeeds = false;
      state.getAllFeedsResponse = {};
    },

    clearBuddyFeeds(state) {
      state.getBuddyFeedStatus = 'IDLE';
      state.isFetchingBuddyFeed = false;
      state.getBuddyFeedResponse = {};
    },

    clearAddLikeToBuddy(state) {
      state.addFeedLikeStatus = 'IDLE';
      state.isFetchingAddLike = false;
      state.addBuddyFeedResponse = {};
      state.likedPostId = null;
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStudentFeedsRequest.pending, (state) => {
        state.getAllFeedsStatus = 'PENDING';
        state.isFetchingAllFeeds = true;
      })
      .addCase(getAllStudentFeedsRequest.fulfilled, (state, action) => {
        state.getAllFeedsStatus = 'SUCCESS';
        state.isFetchingAllFeeds = false;
        state.getAllFeedsResponse = action.payload;
      })
      .addCase(getAllStudentFeedsRequest.rejected, (state) => {
        state.getAllFeedsStatus = 'FAILED';
        state.isFetchingAllFeeds = false;
      })
      .addCase(getBuddyFeedsRequest.pending, (state) => {
        state.getBuddyFeedStatus = 'PENDING';
        state.isFetchingBuddyFeed = true;
      })
      .addCase(getBuddyFeedsRequest.fulfilled, (state, action) => {
        state.getBuddyFeedStatus = 'SUCCESS';
        state.isFetchingBuddyFeed = false;
        state.getBuddyFeedResponse = action.payload;
      })
      .addCase(getBuddyFeedsRequest.rejected, (state) => {
        state.getBuddyFeedStatus = 'FAILED';
        state.isFetchingBuddyFeed = false;
      })
      .addCase(addLikeToBuddyFeedRequest.pending, (state) => {
        state.addFeedLikeStatus = 'PENDING';
        state.isFetchingAddLike = true;
      })
      .addCase(addLikeToBuddyFeedRequest.fulfilled, (state, action) => {
        state.addFeedLikeStatus = 'SUCCESS';
        state.isFetchingAddLike = false;
        state.addBuddyFeedResponse = action.payload;
        state.likedPostId = action.meta.arg.postId;
      })
      // .addCase(addLikeToBuddyFeedRequest.rejected, (state, action: any) => {
      //   state.addFeedLikeStatus = 'FAILED';
      //   state.isFetchingAddLike = false;
      //   const errorXml = new DOMParser().parseFromString(action.error.message, 'text/xml');
      //   const errorMessage = errorXml.querySelector('message')?.textContent || 'An error occurred';
      //   state.error = errorMessage;
      // });
      .addCase(addLikeToBuddyFeedRequest.rejected, (state, action: any) => {
        state.addFeedLikeStatus = 'FAILED';
        state.isFetchingAddLike = false;
        const errorXml = new DOMParser().parseFromString(action.error.message, 'text/xml');
        const status = errorXml.querySelector('status')?.textContent;
        if (status === '400') {
          state.error = 'You have already liked this post';
        } else {
          state.error = 'An error occurred';
        }
      });
  },
});

export const { clearAllFeeds, clearBuddyFeeds, clearAddLikeToBuddy } = StudenLifeSlice.actions;

export const getStudentLifeSelector = (state: RootState) => state.studentLife;

export default StudenLifeSlice.reducer;
