import "../ViewProfileComponent/ViewProfile.scss";
import "./AddBuddyRating.scss";
import {
  Spin,
  Layout,
  Row,
  Avatar,
  Col,
  Input,
  Rate,
  Divider,
  Button,
  notification,
} from "antd";
import HeaderMain from "../../common/HeaderMain/HeaderMain";
import defaultCoverPic from "../../../assets/images/cover-photo-small.jpeg";
import { UserOutlined } from "@ant-design/icons";
import User from "../../../assets/images/user.png";
import Ribbon from "antd/lib/badge/Ribbon";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import {
  addBuddyRatingRequest,
  buddyRatingSelector,
  fetUniversityDetailsRequest,
  fetchBuddyDetailsRequest,
} from "./AddBuddyRatingSlice";
import CustomFooter from "../../common/Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUniversityResourceSelector } from "../../../MainScreen/MainScreenSlice";
import { fetchUniversityResourceById } from "../../../MainScreen/MainScreenSlice";
import { useLocation } from "react-router-dom";
import { NotificationPlacement } from "antd/lib/notification";
import successIcom from "../../../assets/images/round-success.png";

const RenderAcknowledgementScreen = ({
  firstName,
  color,
  handleSubmit,
}: any) => {
  return (
    <>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        className="label"
        style={{ marginBottom: "5px", marginTop: "10px" }}
      >
        <img src={successIcom} alt="success-logo" />
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="label">
        Thank you for rating {firstName ? firstName : "Paul"} !
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="comment-title">
        Would you like to recommend this Ambassador profile to your friend?
      </Row>
      <div style={{ marginTop: "20px" }} className="submit-button-wrapper">
        <Button
          className="submit-button"
          type="primary"
          onClick={handleSubmit}
          style={{
            background: `${color ?? "#005993"}`,
            borderColor: `${color ?? "#005993"}`,
          }}
        >
          <div className="submit-text">Share profile link</div>
        </Button>
      </div>
    </>
  );
};

const AddBuddyRating = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { TextArea } = Input;
  let dispatch = useDispatch();
  const location = useLocation();
  const {
    statusAdd,
    statusGet,
    resoponseGet,
    statusGetBuddy,
    responseGetBuddy,
  } = useAppSelector(buddyRatingSelector);
  const { isFetching, status, payload } = useAppSelector(
    getUniversityResourceSelector
  );
  const [api, contextHolder] = notification.useNotification();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [rating, setRating] = useState(4);
  const [university, setUniversity] = useState<any>({});
  const [buddyDetails, setBuddyDetails] = useState<any>({});
  const [prospectPublicId, setProspectPublicId] = useState("");
  const [buddyPublicId, setBuddyId] = useState("");
  const [uniId, setUniId] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const prospectPublicId = searchParams.get("prospectPublicId")
      ? searchParams.get("prospectPublicId")
      : "jfTTgIuk";

    if (prospectPublicId) {
      setProspectPublicId(prospectPublicId);
    }

    const buddyPublicId = searchParams.get("buddyPubId")
      ? searchParams.get("buddyPubId")
      : "a23d8e5f-efec-400b-b981-a1b7f15552cb";

    if (buddyPublicId) {
      setBuddyId(buddyPublicId);
    }

    const uniId = searchParams.get("uniId")
      ? searchParams.get("uniId")
      : "demo.univizer.io";

    if (uniId) {
      setUniId(uniId);
      dispatch(fetchUniversityResourceById(uniId));
    }

    if (buddyPublicId && prospectPublicId) {
      dispatch(
        fetchBuddyDetailsRequest({
          buddyPublicId: buddyPublicId,
          prospectPublicId: prospectPublicId,
        })
      );
    }

    // Use the extracted parameters as needed
  }, [location]);

  useEffect(() => {
    if (statusGetBuddy === "SUCCESS") {
      if (resoponseGet) {
        setBuddyDetails(responseGetBuddy);
      }
    }
  }, [statusGetBuddy]);

  useEffect(() => {
    if (status === "success") {
      if (payload) {
        setUniversity(payload);
      }
    }
  }, [status]);

  useEffect(() => {
    if (statusAdd === "SUCCESS") {
      setDescription("");
    } else if (statusAdd === "FAILED") {
      toast.error("Something went wrong !");
    }
  }, [statusAdd]);

  const handleSubmit = () => {
    let obj = {
      ratings: rating,
      comment: description,
      prospectPublicId: prospectPublicId,
      buddyPublicId: buddyPublicId,
    };

    dispatch(addBuddyRatingRequest(obj));
  };

  const renderRatingBox = (rate: any) => {
    return (
      <div
        className={`rating-box ${
          rate + 1 <= rating ? "rating-box-checked" : "rating-box-unchecked"
        }`}
      >
        {rate + 1}
      </div>
    );
  };

  const openNotification = (placement: NotificationPlacement) => {
    api.open({
      message: (
        <div style={{ color: "#fff" }}>Profile link copied to clipboard</div>
      ),
      className: "notification",
      placement,
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        width: 400,
        background: "#019C58",
        color: "#fff !important",
        fontFamily: "roboto",
      },
    });
  };

  const handleShareLink = () => {
    const linkToCopy = buddyDetails?.profileLink;
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        openNotification("top");
        console.log("Link copied to clipboard:", linkToCopy);
      })
      .catch((error) => {
        console.error("Failed to copy link:", error);
      });
  };

  return (
    <div style={{ height: "100vh" }} className="mainLayout">
      {contextHolder}
      <Layout>
        <HeaderMain />
        <Layout>
          <Content>
            <Content className="viewProfile">
              <div className="banner">
                {/* <div className="bannerImgWrapper"> */}
                <img
                  src={
                    buddyDetails?.profile?.banner?.imageUrl
                      ? buddyDetails?.profile?.banner?.imageUrl
                      : defaultCoverPic
                  }
                  alt="Banner Image"
                />
                {/* </div> */}
                <div className="avatar">
                  <img
                    src={
                      buddyDetails?.profile?.mediumProfileUrl
                        ? buddyDetails?.profile?.mediumProfileUrl
                        : User
                    }
                    alt="Avatar"
                  />
                </div>
                <div className="designation-details">
                  <div className="course-name">
                    {buddyDetails?.profile?.firstName
                      ? buddyDetails?.profile?.firstName
                      : "Paul"}
                  </div>
                  <div className="degree">
                    {buddyDetails?.profile?.courseInfo?.displayName
                      ? buddyDetails?.profile?.courseInfo?.displayName
                      : "BSc in Software Engineering"}
                  </div>
                  <div className="designation">
                    {buddyDetails?.profile?.type
                      ? buddyDetails?.profile?.type
                      : "Web Developer"}
                  </div>
                </div>
              </div>
            </Content>
            <Content className="content-wrapper">
              <div className="form-container">
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  className="rating-comment-box-wrapper"
                >
                  <Col xs={24} xl={8}>
                    <Divider />
                  </Col>
                </Row>
                {statusAdd === "SUCCESS" ? (
                  <RenderAcknowledgementScreen
                    firstName={buddyDetails?.profile?.firstName}
                    color={university?.theme?.themeColor}
                    handleSubmit={handleShareLink}
                  />
                ) : (
                  <>
                    <Row
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      className="label"
                    >
                      Thanks for chatting with{" "}
                      {buddyDetails?.profile?.firstName
                        ? buddyDetails?.profile?.firstName
                        : "Paul"}
                      .
                    </Row>
                    <Row
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      className="second-label"
                    >
                      Please rate your conversation
                    </Row>
                    <div className="rating-label">Add Rating</div>
                    <Row
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      className="rating-container"
                    >
                      <Rate
                        defaultValue={rating}
                        character={({ index }) => renderRatingBox(index)}
                        onChange={(value) => setRating(value)}
                      />
                    </Row>
                    <Row
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      // className="rating-container"
                      justify="center"
                    >
                      <Col xs={20} sm={16} md={12} lg={8} xl={4}>
                        <div className="rating-definition">
                          <div>Not satisfied</div>
                          <div>Very Satisfied</div>
                        </div>
                      </Col>
                    </Row>
                    <Row
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      className="comment-title"
                    >
                      <Col xs={24} xl={8}>
                        Do you have any additional feedback for{" "}
                        {buddyDetails?.profile?.firstName &&
                          buddyDetails?.profile?.firstName}
                        ?
                      </Col>
                    </Row>
                    <Row
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      className="rating-comment-box-wrapper"
                    >
                      <Col xs={24} xl={8}>
                        <TextArea
                          placeholder="Type your feedback here"
                          rows={4}
                          className="rating-comment-box"
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <Divider />
                      </Col>
                    </Row>
                    <div className="submit-button-wrapper">
                      <Button
                        className="submit-button"
                        type="primary"
                        onClick={handleSubmit}
                        style={{
                          background: `${
                            university?.theme?.themeColor ?? "#005993"
                          }`,
                          borderColor: `${
                            university?.theme?.themeColor ?? "#005993"
                          }`,
                        }}
                      >
                        <div className="submit-text">Submit</div>
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Content>
          </Content>
        </Layout>
      </Layout>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: "transparent",
          marginTop: "50px !importaant",
        }}
      >
        <CustomFooter />
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddBuddyRating;
