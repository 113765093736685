export type ChatAreaProps ={
    body: string
    dateCreated: string
    from: string
    id: string
    index:string | null
    media: string | null
    mediaType: string
    favoriteMessage:boolean
    to: string
    type: TypeOFChat
}

export enum TypeOFChat { 'P_TO_B'= 'P_TO_B' , 'B_TO_P' = 'B_TO_P'}