import { Button, Checkbox, Col, Form, Input, message, Row } from 'antd';
import './NoAmbassadorsForm.scss';
import PhoneInput from 'react-phone-input-2';
import { useEffect, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import MultiSelectDropdown from '../../../common/MultipleSelect/CustomMaltiSelect';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useAppSelector } from '../../../../hooks';
import { getHost } from '../../../../utils/hostDataFunction';
import MessageSuccessPopup from '../SayHelloPopup/MessageSuccessPopup';
import { useCountryCode } from '../PeopleTab/useCountryCode';
import { getUniversityResourceSelector } from '../../../../MainScreen/MainScreenSlice';

interface FormData {
  fullName: string;
  email: string;
  dialCode: string;
  phone: string;
  countryCode: string;
  whatsapp?: string;
  courseId: string[];
  channels: string[];
}

interface StructureProgrammes {
  label: string;
  value: CheckboxValueType;
}

interface Props {
  structureProgrammes: StructureProgrammes[];
  clearFilter: (filterObj: any) => void;
}

const NoAmbassadorsForm: React.FC<Props> = ({ structureProgrammes, clearFilter }) => {
  const [isWhatsapp, setIsWhatsapp] = useState<boolean>(true);
  const prospectSelectedCourses = useAppSelector((state) => state.chatPopups.selectedCourse);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [clearCourseFlag, setClearCourseFlag] = useState<boolean>(false);
  const [form] = Form.useForm<FormData>();
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    email: '',
    dialCode: '',
    phone: '',
    countryCode: '',
    whatsapp: '',
    courseId: [],
    channels: ['EMAIL'],
  });

  const countryCode = useCountryCode();
  const colSpan = window.innerWidth < 1100 ? 24 : 12;
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>('');
  const { payload } = useAppSelector(getUniversityResourceSelector);

  useEffect(() => {
    setSelectedCountryCode(countryCode);
  }, [countryCode]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (value: string, { dialCode = '', countryCode = '' }) => {
    setFormData((prev) => ({
      ...prev,
      phone: value,
      dialCode,
      countryCode: countryCode.toUpperCase(),
    }));
    setSelectedCountryCode(countryCode);
  };

  const handleWhatsappChange = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    setIsWhatsapp(isChecked);
    if (!isChecked) {
      setFormData((prev) => ({ ...prev, whatsapp: '' }));
    }
  };

  const handleCourses = (selectedCourses: CheckboxValueType[]) => {
    const courseIds = selectedCourses.map(String);
    setFormData((prev) => ({ ...prev, courseId: courseIds }));
  };

  const handleSubmit = async () => {
    let uniId = getHost();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/no-buddy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          uniId: uniId ? uniId : '',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsModalVisible(true);
        form.resetFields();
        setClearCourseFlag(true);
        setTimeout(() => {
          clearFilter({});
        }, 10000);
      } else {
        const errorData = await response.json();
        message.error(`Failed to submit the form: ${errorData.message}`);
      }
    } catch (error) {
      message.error('Failed to submit the form. Please try again.');
    }
  };

  const subTitle = (
    <>
      <div className="subtitle">
        You will receive an email notification when a new ambassador is added that matches your search criteria.
      </div>
    </>
  );

  useEffect(() => {});

  return (
    <div className="ambassadors__form-container">
      <div className="ambassadors__title-container">
        <p className="ambassadors__form-heading">
          Let us know which course <br /> ambassadors are you looking for?
        </p>
      </div>
      <Form className="form__container" onFinish={handleSubmit} form={form}>
        <Row gutter={[16, 12]}>
          <Col span={colSpan}>
            <Form.Item
              label="Full name"
              name="name"
              rules={[{ required: true, message: 'Please enter your name' }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="form__item"
            >
              <Input
                placeholder="Enter full name"
                size="large"
                className="form__input"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
              />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Email address"
              name="email"
              rules={[
                { required: true, message: 'Please enter your email address' },
                { type: 'email', message: 'Please enter a valid email address' },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="form__item"
            >
              <Input
                placeholder="Enter email address"
                size="large"
                className="form__input"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Your mobile number"
              name="number"
              rules={[{ required: true, message: 'Please enter your mobile number' }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="form__item"
            >
              <PhoneInput
                country={selectedCountryCode}
                enableSearch
                placeholder="Enter phone number"
                inputClass="phone__input"
                value={formData.phone}
                onChange={handlePhoneChange}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="whatsapp__checkbox">
            <Checkbox checked={isWhatsapp} onChange={handleWhatsappChange}>
              This is also my whatsapp number
            </Checkbox>
          </Col>
          {!isWhatsapp && (
            <Col span={24}>
              <Form.Item
                label="Your whatsapp number"
                name="whatsapp"
                rules={[{ required: true, message: 'Please enter your mobile number' }]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className="form__item"
              >
                <PhoneInput
                  country={selectedCountryCode}
                  enableSearch
                  placeholder="Enter whatsapp number"
                  inputClass="phone__input"
                  value={formData.whatsapp}
                  onChange={(value) => setFormData((prev) => ({ ...prev, whatsapp: value }))}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={colSpan} className="dropdown">
            <p>Your course intrest</p>
            <MultiSelectDropdown
              options={structureProgrammes}
              onChange={handleCourses}
              value={prospectSelectedCourses}
              clearCourses={clearCourseFlag}
            />
          </Col>
          <Col span={24}>
            <Form.Item className="form__item">
              <Button
                type="primary"
                htmlType="submit"
                className="submit__button"
                size="large"
                style={{ background: `${payload?.theme?.themeColor}` }}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <MessageSuccessPopup
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        title="Your information has been submitted!"
        subTitle={subTitle}
      />
    </div>
  );
};

export default NoAmbassadorsForm;
