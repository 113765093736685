import React, { useEffect, useState } from 'react';
import '../App.scss';
import { Layout, Tabs } from 'antd';
import Body from '../components/body/Body';
import { fetchUniversityResourceById, getUniversityResourceSelector } from './MainScreenSlice';
import { useDispatch } from 'react-redux';
import './MainScreen.scss';
import './MobileMenu';
import { useAppSelector } from '../hooks';
import CustomFooter from '../components/common/Footer/Footer';
import i18next from 'i18next';
import HeaderMain from '../components/common/HeaderMain/HeaderMain';
import { getHost } from '../utils/hostDataFunction';
import type { TabsProps } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { StudentLife } from '../components/body/StudentLife/StudentLife';
import { MdDynamicFeed, MdOutlineSupervisedUserCircle } from 'react-icons/md';

const { Content } = Layout;

function MainScreen() {
  const [enableWordPress, setEnableWordPress] = useState<any>(false);
  const [enableStudentLife, setEnableStudentLife] = useState<boolean>(false);
  const [enableBothTabs, setEnableBothTabs] = useState<any>(false);
  const [showFooter, setShowFooter] = useState<boolean>(false);
  // const {id} = useParams();
  let dispatch = useDispatch();
  const buddyList = useAppSelector((state) => state.getUniversityBuddyList);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const wordPress = searchParams.get('wordpress');
    const studentLife = searchParams.get('studentLife');

    if (wordPress && studentLife) {
      setEnableBothTabs(true);
      setEnableWordPress(true);
    } else if (wordPress) {
      setEnableWordPress(true);
    } else if (studentLife) {
      setEnableStudentLife(true);
    }

    // let host = getHost();
    // console.log("=== host ===", host);

    // if (host) {
    //   dispatch(fetchUniversityResourceById(host));
    // }
  }, []);

  const changeLanguage = (e: any) => {
    i18next.changeLanguage(e);
  };

  // console.log("=== enableWordPress ===", enableWordPress);
  // console.log("=== enableBothTabs ===", enableBothTabs);

  // const renderTabTitles = (tab: string) => {
  //   switch (tab) {
  //     case 'profile':
  //       return (
  //         <>
  //           <div>
  //             <MdOutlineSupervisedUserCircle size={43} />
  //           </div>
  //           <div className="wordpressTabTile">Profile</div>
  //         </>
  //       );

  //     case 'life':
  //       return (
  //         <>
  //           <div>
  //             <MdDynamicFeed size={43} />
  //           </div>
  //           <div className="wordpressTabTile">Student Life</div>
  //         </>
  //       );

  //     default:
  //       return null;
  //   }
  // };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFooter(true);
    }, 3000);
    return () => {
      clearTimeout(timer);
      setShowFooter(false);
    };
  }, [buddyList]);

  return (
    <div className="main-content">
      <Layout>
        {!(enableWordPress || enableStudentLife) && <HeaderMain />}
        {/* {enableWordPress ? (
          <Tabs
            defaultActiveKey="1"
            style={{ padding: "40px" }}
            tabBarExtraContent={
              <div
                className="home-layout container"
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                <p key="cop" className="copy" style={{ marginRight: "10px" }}>
                  Powered by
                </p>

                <img
                  className="univiserLogo"
                  src="https://uploads-ssl.webflow.com/6049cc7c5a2ed9ce1141738b/605489a42906a8585acb3e93_Univiser%20logo-01-p-500.png"
                  alt="Univiser Logo"
                  style={{ maxWidth: "200px" }}
                />
              </div>
            }
          >
            {enableBothTabs ? (
              <>
                <TabPane tab={renderTabTitles("profile")} key="1">
                  <Layout className="mainLayout">
                    <Content>
                      <Body />
                    </Content>
                  </Layout>
                </TabPane>
                <TabPane tab={renderTabTitles("life")} key="2">
                  <StudentLife />
                </TabPane>
              </>
            ) : enableWordPress ? (
              <TabPane tab={renderTabTitles("profile")} key="1">
                <Layout className="mainLayout">
                  <Content>
                    <Body />
                  </Content>
                </Layout>
              </TabPane>
            ) : (
              <></>
            )}
          </Tabs>
        ) : (
          <></>
        )} */}

        {/* {!enableWordPress ? ( */}
        <Layout className={enableWordPress ? 'whiteBackground' : 'grayBackground'}>
          <Content>
            <Body />
          </Content>
        </Layout>
        {/* ) : (
          <></>
        )} */}

        {!enableBothTabs && showFooter && <CustomFooter />}
      </Layout>
    </div>
  );
}

export default MainScreen;
