import { useEffect, useState } from 'react';

export const useCountryCode = () => {
  const [countryCode, setCountryCode] = useState<string>('');

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch(`https://ipapi.co/json/`);
        const data = await response.json();
        setCountryCode(data.country_code.toLowerCase());
      } catch (error) {
        console.error('Error fetching IP information:', error);
      }
    };

    fetchCountryCode();
  }, []);

  return countryCode;
};
