import React from "react";
import "./Footer.scss";


const CustomFooter = (): JSX.Element => (
    <div className="home-layout-wrapper home-footer-wrapper FooterLayoutMobile">
        <div className="home-layout container">
            <p key="cop" className="copy">
                Powered by
            </p>

            <img
                className="univiserLogo"
                src="https://uploads-ssl.webflow.com/6049cc7c5a2ed9ce1141738b/605489a42906a8585acb3e93_Univiser%20logo-01-p-500.png"
                alt="Univiser Logo"
            />
        </div>
        {/* <div className="feedbackSupportLink">
            <Feedback/>
        </div> */}
    </div>
);

export default CustomFooter;
