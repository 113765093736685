import { FiSend } from '@react-icons/all-files/fi/FiSend';
import { Avatar, Button, Card, Col, Row, Tooltip } from 'antd';
import { getReplyTime } from '../../../common/utils';
import { BsFillChatLeftTextFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { getBuddyViewSelector } from '../../ViewProfileComponent/ViewProfileSlice';
import { useAppSelector } from '../../../../hooks';
import { useState } from 'react';
import SayHelloPopup from '../../Home/SayHelloPopup/SayHelloPopup';
import { minutesFormatter } from '../../../../utils/fomatter';

const { Meta } = Card;
export const DetailCard = ({ buddyDetail, payload, structureProgrammes }: any) => {
  const { buddyView, buddyViewFetchStatus, isFetchingBuddyView } = useAppSelector(getBuddyViewSelector);

  const [sayhelloVisible, setSayhelloVisible] = useState<boolean>(false);
  const [isMessageSuccessModalVisible, setIsMessageSuccessModalVisible] = useState<boolean>(false);

  // console.log('=== buddyDetails ===', buddyDetail);
  const navigate = useNavigate();
  const buddyId = buddyDetail?.buddyId;

  const replyTimeInMinutes = buddyDetail?.averageResponseTime / 60000;
  const formattedReplyTime = minutesFormatter(replyTimeInMinutes);

  return (
    <Card className="slPostDetailCard">
      {/* {buddyViewFetchStatus === "success" && ( */}
      <SayHelloPopup
        buddies={buddyView}
        visible={sayhelloVisible}
        setVisible={(isVisible, isSuccess) => {
          setSayhelloVisible(isVisible);
          setIsMessageSuccessModalVisible(isSuccess);
        }}
        id={buddyId}
        structureProgrammes={structureProgrammes}
      />
      {/* )} */}
      <Row gutter={16} className="slPostDetailCardStatRow">
        <Col span={24}>
          <div className="slPostDetailCardMeta">
            <Avatar src={buddyDetail?.smallProfileUrl} size={64} />
            <div className="slPostDetailCardMetaDetail">
              <div className="slPostDetailCardMetaName">{buddyDetail?.name}</div>
              <div className="slPostDetailCardMetaCourse">{buddyDetail?.course}</div>
              <div className="slPostDetailCardMetaProgramme">{buddyDetail?.programme}</div>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={16} className="slPostDetailCardStatRow">
        <Col lg={8} md={24} xs={12} className="slPostDetailCardStatColumn">
          <Card className="slPostDetailCardStatCard">
            <div className="slPostDetailCardStatCardTitle">I Speak</div>
            <div>
              {buddyDetail?.languages?.slice(0, 3).map((language: any, key: any) => (
                <div className="slPostDetailCardStatCardDesc" key={key} style={{ lineHeight: '15px' }}>
                  {language?.name}
                </div>
              ))}
              {buddyDetail?.languages && buddyDetail?.languages.length > 3 && (
                <Tooltip
                  placement="top"
                  align={{ offset: [-35, 10] }}
                  title={buddyDetail?.languages
                    .slice(3)
                    .map((language: any) => language.name)
                    .join(', ')}
                >
                  <div className="languages_list">+{buddyDetail?.languages.length - 3}</div>
                </Tooltip>
              )}
            </div>
          </Card>
        </Col>
        <Col lg={8} md={24} xs={12} className="slPostDetailCardStatColumn">
          <Card className="slPostDetailCardStatCard">
            <div className="slPostDetailCardStatCardTitle">I'm interested in</div>
            <div className="slPostDetailCardStatCardDesc" style={{ display: 'flex', justifyContent: 'center' }}>
              {buddyDetail?.interests
                ?.slice(0, 3)
                .map(
                  (interest: any, key: any) =>
                    interest.url && <img src={interest.url} alt={interest.name} key={key} className="interests" />
                )}
              {buddyDetail?.interests?.length > 3 && (
                <Tooltip
                  placement="top"
                  title={buddyDetail.interests
                    .slice(3)
                    .map((interest: any) => interest.name)
                    .join(', ')}
                >
                  <div style={{ marginLeft: '6px' }}>+{buddyDetail?.interests?.length - 3}</div>
                </Tooltip>
              )}
            </div>
          </Card>
        </Col>
        <Col lg={8} md={24} xs={24} className="slPostDetailCardStatColumn">
          <Card className="slPostDetailCardStatCard">
            <div className="slPostDetailCardStatCardTitle">Typically replies in</div>
            <div className="slPostDetailCardReplyTimeStatCardDesc">{formattedReplyTime}</div>
          </Card>
        </Col>
      </Row>

      <p>{buddyDetail?.profile?.description}</p>
      <Row className="slPostDetailCardHelloButtonRow">
        <Col span={24} className="slPostDetailCardHelloButtonColumn">
          <Button
            icon={<BsFillChatLeftTextFill style={{ marginRight: '8px' }} />}
            className="slPostDetailCardHelloButton"
            size="large"
            style={{
              backgroundColor: `${payload?.theme?.themeColor}`,
              borderColor: `${payload?.theme?.themeColor}`,
            }}
            onClick={() => setSayhelloVisible(true)}
          >
            Say Hello
          </Button>
        </Col>
      </Row>

      <Row className="slPostDetailCardViewPostRow" justify="center" align="middle">
        <Col span={24} className="slPostDetailCardViewPostColumn">
          <u onClick={() => navigate(`/buddy/${buddyDetail?.buddyId}`)}>View profile</u>
        </Col>
      </Row>
    </Card>
  );
};
