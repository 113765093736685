import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { getPersonPostsResourceRequest } from './ViewProfilePostsAPI';

export interface PersonPostsProps {
  id: number;
  textBody: string;
  media: [
    {
      id: number;
      url: string;
      order: number;
    }
  ];
  likeCount: number;
  buddy: {
    buddyId: number;
    name: string;
    course: string;
    programme: string;
    smallProfileUrl: string;
    averageResponseTime: null;
    description: string;
    languages: [
      {
        code: string;
        name: string;
        url: string;
      }
    ];
    interests: [
      {
        code: string;
        name: string;
        url: string;
      }
    ];
  };
  created: string;
}

interface PersonPostsResponseProps {
  isFetchingPersonPostsPersonPosts: boolean;
  personPostsStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  PersonPostResponse: any;
}

const initialState: PersonPostsResponseProps = {
  personPostsStatus: 'IDLE',
  isFetchingPersonPostsPersonPosts: false,
  PersonPostResponse: [],
};

export const fetchPersonPostsResourceById = createAsyncThunk('get/person/post', async (id: any) => {
  // console.log("=== came to slice ===");

  return await getPersonPostsResourceRequest({ id });
});

const PersonPostsSlice = createSlice({
  name: 'getPersonPostResource',
  initialState: initialState,
  reducers: {
    clearPersonPostsResourceRespose(state) {
      state.personPostsStatus = 'IDLE';
      state.isFetchingPersonPostsPersonPosts = false;
      state.PersonPostResponse = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPersonPostsResourceById.pending, (state) => {
        state.personPostsStatus = 'PENDING';
        state.isFetchingPersonPostsPersonPosts = true;
      })
      .addCase(fetchPersonPostsResourceById.fulfilled, (state, action) => {
        // Add user to the state array
        state.personPostsStatus = 'SUCCESS';
        state.isFetchingPersonPostsPersonPosts = false;
        state.PersonPostResponse = action.payload;
      })
      .addCase(fetchPersonPostsResourceById.rejected, (state) => {
        state.personPostsStatus = 'FAILED';
        state.isFetchingPersonPostsPersonPosts = false;
      });
  },
});

export const getPersonPostsSelector = (state: RootState) => state.personPosts;
export const { clearPersonPostsResourceRespose } = PersonPostsSlice.actions;
export default PersonPostsSlice.reducer;
