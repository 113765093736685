import { Card, Col, Layout, Row, Typography } from 'antd';
import SampleWallPaper from '../../../../assets/images/sample-wall-paper.png';
import './PopularPostCard.scss';
import { checkMediaTypeByUrl, handleMainImageVideo } from '../common';
import ReactPlayer from 'react-player';
import { FaPlayCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import PageLoader from '../../../PageLoader';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const { Title, Paragraph } = Typography;

export const PopularPostCard = ({ popularPosts }: any) => {
  // console.log("=== popularPosts ===", popularPosts);
  const navigate = useNavigate();

  const handleMainMedia = (data: any) => {
    let minObj = handleMainImageVideo(data);
    return minObj?.url;
  };

  const handleMediaType = (data: any) => {
    const url = handleMainMedia(data);
    const type = checkMediaTypeByUrl(url);
    return type === 'image' ? (
      <LazyLoadImage
        effect="blur"
        wrapperProps={{
          style: { transitionDelay: '1s' },
        }}
        src={url}
        alt="Popular post"
        style={{ width: '100%', height: '200px', objectFit: 'cover' }}
        className="slPcImage"
      />
    ) : (
      <ReactPlayer
        url={url}
        width="100%"
        playIcon={<FaPlayCircle size={100} color="#d3d7cf" />}
        light
        className="slPcImage"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    );
  };

  const handleReadMoreOnClick = (postData: any) => {
    navigate('/postDetail', { state: { data: postData } });
  };

  const renderWrappedTopic = (description: any) => {
    // Remove HTML tags
    let plainText = description.replace(/<[^>]*>/g, '');
    let words = plainText.split(' ').slice(0, 30).join(' ');
    return words + '...';
  };

  return (
    <Card className="slPcMain">
      <Title level={4}>Popular Posts</Title>
      {popularPosts?.length > 0 &&
        popularPosts?.map((item: any, key: any) => {
          return (
            <Card className="slPcSub" key={key} onClick={() => handleReadMoreOnClick(item)}>
              <Row className="slPcImageRow">
                <Col span={24}>{item?.media.length > 0 ? handleMediaType(item?.media) : ''}</Col>
              </Row>

              {/* <p className="slPcDescription">
                {item?.textBody ? <>{renderWrappedTopic(item?.textBody)} ...</> : <></>}
              </p> */}
              <Paragraph className="slPcDescription" ellipsis={{ rows: 4, expandable: false, symbol: '...' }}>
                {item?.textBody ? renderWrappedTopic(item?.textBody) : ''}
              </Paragraph>

              <Row className="slPcFooter">
                <Col className="slPcFooterReadMore">
                  <u onClick={() => handleReadMoreOnClick(item)}>Read more</u>
                </Col>
                <Col className="slMpLikeText">{item?.likeCount} Likes</Col>
                {/* <Col span={2} className="slPcFooterShares slMpLikeText">
                  14 Shares
                </Col> */}
              </Row>
            </Card>
          );
        })}

      {/* <Card className="slPcSub">
        <Row className="slPcImageRow">
          <img src={SampleWallPaper} alt="sample" style={{ maxWidth: "100%", height: "auto" }} className="slPcImage" />
        </Row>
        <p className="slPcDescription">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book.
        </p>

        <Row className="slPcFooter">
          <Col span={4} className="slPcFooterReadMore">
            <u>Read more</u>
          </Col>
          <Col span={2} offset={16} className="slPcFooterLikes">
            23 Likes
          </Col>
          <Col span={2} className="slPcFooterShares">
            14 Shares
          </Col>
        </Row>
      </Card> */}
    </Card>
  );
};
