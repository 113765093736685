import { getHost } from '../../../../../utils/hostDataFunction';

export type MessageReadProps = {
  buddyID: number;
  pubId: any;
};

export const readMessageRequest = async (message: MessageReadProps) => {
  // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
  // console.log('message', message);
  let sessionDetails = JSON.parse(localStorage.getItem('sessionDetails') as string);
  let header: any = {
    'Content-Type': 'application/json',
    uniId: getHost(),
    pubId: message?.pubId,
  };

  if (sessionDetails?.sessionId) {
    header.session = sessionDetails?.sessionId;
  }

  return await fetch(`${process.env.REACT_APP_API_URL}/chat/buddy/${message.buddyID}/ack`, {
    method: 'GET',
    headers: header,
  }).then((res) => res.json());
};
