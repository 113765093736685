import React, { useRef } from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

interface AutocompleteInputProps {
  onPlaceSelected: (place: { place_id: string; name: string; address: string }) => void;
}

const libraries: 'places'[] = ['places'];

const GoogleAutocompleteInput: React.FC<AutocompleteInputProps> = ({ onPlaceSelected }) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current?.getPlace();
    if (place && place.place_id && place.formatted_address) {
      onPlaceSelected({
        place_id: place.place_id,
        name: place.name || 'No name available',
        address: place.formatted_address,
      });
    }
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY!} libraries={libraries}>
      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={handlePlaceChanged}
        options={{ fields: ['place_id', 'name', 'formatted_address'] }}
      >
        <input
          type="text"
          required
          placeholder="Enter Your City"
          style={{ width: '100%', padding: '8px', border: '1px solid #ddd', borderRadius: '4px' }}
        />
      </Autocomplete>
    </LoadScript>
  );
};

export default GoogleAutocompleteInput;
