import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import './App.scss';
import MainScreen from './MainScreen/MainScreen';
import { Route, Routes } from 'react-router-dom';
import ViewProfile from './components/body/ViewProfileComponent/ViewProfile';
import PeopleTab from './components/body/Home/PeopleTab/PeopleTab';
import SharePageEmailPopup from './components/body/Home/SharePageEmailPopup/SharePageEmailPopup';
import MyChats from './components/body/Home/MyChats/MyChats';
import Chat from './components/body/Chat/Chat';
import { useAppSelector } from './hooks';
import { registerProspectSelector } from './components/body/RegisterProspect/RegisterProspectSlice';
import AddBuddyRating from './components/body/Rating/AddBuddyRating';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { fetchIpDetails } from './api/IpDataSlice';
import { StudentLife } from './components/body/StudentLife/StudentLife';
import { PostDetail } from './components/body/StudentLife/PostDetails/PostDetail';
import StudentLifePage from './components/body/StudentLife/StudentLifeNew';
import { getHost } from './utils/hostDataFunction';
import { fetchUniversityResourceById, getUniversityResourceSelector } from './MainScreen/MainScreenSlice';

const TRACKING_ID = 'UA-277681511-1'; // OUR_TRACKING_ID

export const IsUserLoggedContext = React.createContext(false);
function App() {
  // ReactGA.initialize(TRACKING_ID);
  const useSession = JSON.parse(localStorage.getItem('sessionDetails') as string);
  const [isLogged, setisLogged] = useState(false);
  const { status } = useAppSelector(registerProspectSelector);
  const { payload } = useAppSelector(getUniversityResourceSelector);
  let dispatch = useDispatch();

  useEffect(() => {
    let host = getHost();

    if (host) {
      dispatch(fetchUniversityResourceById(host));
    }

    window.document.title = window?.location?.host;
    dispatch(fetchIpDetails());
  }, []);

  useEffect(() => {
    if (payload) {
      // ReactGA.initialize(payload?.univeristyConfig?.analyticsKey);
      // ReactGA.pageview(window.location.pathname + window.location.search);
      // const favicon = document.querySelector("link[rel='icon']");
      // if (favicon) {
      //   favicon.setAttribute('href', payload?.theme?.faviconUrl);
      // }
      // const ogImageMetaTag = document.querySelector('meta[property="og:image"]');
      // if (ogImageMetaTag) {
      //   ogImageMetaTag.setAttribute('content', payload?.theme?.logoUrl);
      // }
      if (payload?.univeristyConfig?.analyticsKey) {
        ReactGA.initialize(payload?.univeristyConfig?.analyticsKey);
        ReactGA.send({
          hitType: 'pageview',
          page: window.location.pathname + window.location.search,
          title: 'Home Page',
        });
      }
    }
  }, [payload]);

  // Below useEffect is commented because we always want to show the complete registration form when the user click the chat, regardless of whether they are already registers or not

  // useEffect(() => {
  //   if (useSession) {
  //     setisLogged(true);
  //   } else {
  //     setisLogged(false);
  //   }
  // }, [status, useSession]);

  return (
    <IsUserLoggedContext.Provider value={isLogged}>
      <Helmet>
        {/* <meta property="og:image" content={payload?.theme?.logoUrl} /> */}
        {/* <meta property="og:image" content="https://univiserstg.blob.core.windows.net/public/demo-uni-logo.PNG" /> */}
        <link rel="icon" href={payload?.theme?.faviconUrl} />
      </Helmet>
      <Routes>
        {/*<Route path="login" element={<Login/>}/>*/}
        <Route path="chat/:id/:chatId" element={<Chat />} />
        {/* <Route path="say-hello/:id" element={<SayHelloPopupTab />} /> */}
        <Route path="/" element={<MainScreen />}>
          <Route path="buddy/:id" element={<ViewProfile />} />
          <Route path="peopleTab" element={<PeopleTab />} />
          <Route path="sharePagePopup" element={<SharePageEmailPopup type={''} />} />
          {/* <Route path="sayHelloPopup" element={<SayHelloPopup />} /> */}
          <Route path="studentLife" element={<StudentLife />} />
          <Route path="studentLifeNew" element={<StudentLifePage />} />
          <Route path="postDetail" element={<PostDetail />} />
        </Route>
        <Route path="buddy-rating" element={<AddBuddyRating />} />
      </Routes>
    </IsUserLoggedContext.Provider>
  );
}

export default App;
