import React, { memo, useState } from "react";
import "./YourSelfContainer.scss";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import { useAppDispatch } from "../../../../hooks";
import { resendOTP } from "./ReSendOTP/ReSendOTPSlice";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";

interface MobileVerificationProps {
  onSubmit: (otp: string) => void;
  otpValidationState: boolean | undefined;
  phoneNumber: string;
  email: string;
  pid: string;
}

const MobileVerification = ({ onSubmit, otpValidationState, phoneNumber, email, pid }: MobileVerificationProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");

  function reSendOTP() {
    dispatch(resendOTP(pid));
  }

  return (
    <>
      <div className="text-center">
        <p className="mt-lg">
          We've sent you a 6-digit code to {phoneNumber} and {email}. Please check your phone or email inbox and enter
          the code here to complete the verification to send your message
        </p>
        <div>
          <Form
            onSubmit={() => {
              onSubmit(otp);
              setOtp("");
            }}
            render={({ handleSubmit }: any) => (
              <form onSubmit={handleSubmit} id={"otpForm"} className="mb-md">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  inputType={"tel"}
                  inputStyle={{ width: 48, margin: 8 }}
                  shouldAutoFocus={true}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={{ justifyContent: "center" }}
                />
                {otpValidationState !== undefined && !otpValidationState && (
                  <p className="inputErrorMessage">OTP is invalid</p>
                )}
              </form>
            )}
          />
          <p>
            Didn't get the code? <a onClick={(event) => reSendOTP()}>Resend</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default memo(MobileVerification);
