import moment from 'moment';

const chatMessageDateFormat = 'MMM Do YYYY, hh:mm A';

export const changeTimeToCurrentTimezone = (date: any, minOffset: any) => {
  if (minOffset) {
    let currentOffset = moment().utcOffset();
    let tempTime = new Date(date).getTime() + (-minOffset + currentOffset) * 60000;

    return moment(tempTime).format(chatMessageDateFormat);
  }

  return moment(date).format(chatMessageDateFormat);
};

export const minutesFormatter = (time: any) => {
  // const sec = (time % 1) * 60;
  const min = time - (time % 1);

  const day = Math.floor(min / 1440);
  const hour = Math.floor((min % 1440) / 60);
  const qMin = min % 60;

  return `${day > 0 ? day + (day === 1 ? ' day' : ' days') : ''} ${
    hour > 0 ? hour + (hour === 1 ? ' hour' : ' hours') : ''
  } ${qMin > 0 ? qMin + ' mins' : ''}`;
};
