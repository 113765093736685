import {GetBuddyListReq} from "./PeopleTabSlice";
import {PathParamGenerator} from "../../../../utils/pathParamGenerator";

export const getBuddyListRequest = async (request :GetBuddyListReq) => {
    // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
    let {filterOptions} = request
    return await fetch(
        
        `${process.env.REACT_APP_API_URL}/university/${request.id}/buddy${PathParamGenerator(filterOptions)}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'X-Forwarded-For' :''
            },

            // body: JSON.stringify({
            //     auth_token:user.auth_token
            // }),
        }
    )
        .then((res) => res.json())
};
