import React, { useEffect, useState } from 'react';
import { message, Modal, Spin } from 'antd';
import { IoMdCheckmarkCircle } from '@react-icons/all-files/io/IoMdCheckmarkCircle';
import './MessageSuccessPopup.scss';
import { useSelector } from 'react-redux';
import { registerProspectSelector } from '../../RegisterProspect/RegisterProspectSlice';
import { useAppSelector } from '../../../../hooks';
import { getUniversityResourceSelector } from '../../../../MainScreen/MainScreenSlice';
import { useTranslation } from 'react-i18next';
import PageLoader from '../../../PageLoader';
import { PersonProp } from '../PeopleTab/PeopleTabSlice';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  prospectData?: any;
  buddies?: PersonProp;
  title?: string;
  subTitle?: JSX.Element;
  additionalInfo?: string;
}

const MessageSuccessPopup: React.FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  prospectData,
  buddies,
  title,
  subTitle,
  additionalInfo,
}) => {
  const [] = useState(true);
  const { status, isFetching, sessionDetails } = useSelector(registerProspectSelector);
  const { payload } = useAppSelector(getUniversityResourceSelector);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const info = () => {
    message.info('Content copied to clipboard');
  };

  useEffect(() => {
    // console.log("sessionDetails", sessionDetails);
  }, [sessionDetails]);

  useEffect(() => {
    if (isModalVisible) {
      const timer = setTimeout(() => {
        setIsModalVisible(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [isModalVisible, setIsModalVisible]);

  const { t } = useTranslation();
  return (
    <>
      <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
        {isFetching ? (
          <PageLoader />
        ) : (
          <div className="messageSuccessPopupTitle">
            <div className="PopupTitleIconHeaderLayout">
              <div className="PopupTitleIcon">
                <IoMdCheckmarkCircle size={50} />
              </div>
              <div className="titleHeaderLayout">
                <div className="titleHeader">{title}</div>
                {/* <div className="secondTitleText">
                  {t("dashboard.MessageSuccessPopup.secondTitleText")}{" "}
                  {sessionDetails?.defaultReplyTime}
                </div> */}
              </div>
            </div>
            <div className="titleHeaderSub">{subTitle}</div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default MessageSuccessPopup;
