import { Modal } from 'antd';
import ViewProfile from '../../body/ViewProfileComponent/ViewProfile';
import { isMobile, isTablet } from 'react-device-detect';
import { getHost } from '../../../utils/hostDataFunction';
import { getUniversityResourceSelector } from '../../../MainScreen/MainScreenSlice';
import { useAppSelector } from '../../../hooks';

export const PersonalDetailViewModal = ({
  selectedId,
  visibleDetailModal,
  handlePersonalViewModal,
  enableWordPress,
}: any) => {
  const { payload } = useAppSelector(getUniversityResourceSelector);

  const uniDomainName = payload?.domain;

  let isShoolini = uniDomainName === ('shoolini.univiser.io' || 'shooliniuniversity.com');

  // console.log('==== isShoolini ====', isShoolini);

  const handleModalWidth = () => {
    if (!isMobile) {
      if (isShoolini) {
        return 1100;
      }
      return 1200;
    } else if (isTablet) {
      return 500;
    } else {
      return 350;
    }
  };

  return (
    <Modal
      title={null}
      open={visibleDetailModal}
      footer={null}
      onCancel={handlePersonalViewModal}
      // width={isShoolini ? 1100 : !isMobile ? 1200 : isTablet ? 500 : 350}
      width={handleModalWidth()}
      destroyOnClose
    >
      <ViewProfile key={selectedId} selectedId={selectedId} enableWordPress={enableWordPress} />
    </Modal>
  );
};
