import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Home/Dashboard/Dashboard";
import ViewProfile from "./ViewProfileComponent/ViewProfile";
import Chat from "./Chat/Chat";
import { StudentLife } from "./StudentLife/StudentLife";
import { PostDetail } from "./StudentLife/PostDetails/PostDetail";
import StudentLifePage from "./StudentLife/StudentLifeNew";

function Body() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="buddy/:id" element={<ViewProfile />} />
        <Route path="chat" element={<Chat />} />
        <Route path="studentLife" element={<StudentLife />} />
        <Route path="postDetail" element={<PostDetail />} />
        <Route path="studentLifeNew" element={<StudentLifePage />} />
      </Routes>
    </>
  );
}

export default Body;
