import { MdSearchOff } from 'react-icons/md';
import './NoAmbassadors.scss';
import NoAmbassadorsForm from './NoAmbassadorsForm';

type Props = {
  structureProgrammes: any;
  clearFilter: (filterObj: any) => void;
};

const NoAmbassadors: React.FC<Props> = ({ structureProgrammes, clearFilter }) => {
  return (
    <div className="buddy__form-container">
      <span className="icon__container">
        <MdSearchOff className="icon" />
      </span>
      <p className="buddy__unavailable-text"> There are no ambassadors matching your current search filters.</p>
      <NoAmbassadorsForm structureProgrammes={structureProgrammes} clearFilter={clearFilter} />
    </div>
  );
};

export default NoAmbassadors;
